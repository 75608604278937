import { Component, OnInit } from '@angular/core';
import { Cookie, Form } from "../../models";
import { ActivatedRoute } from "@angular/router";
import { CookiesService, FormService, LoadingService, LocalizationService } from "../../services";
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent implements OnInit {

  formId: string;
  form: Form;
  sended: boolean = false;
  recapcha: boolean = false;
  recapchaValid: boolean = false;
  recapchaSiteKey: string = '';
  cookieConfig: Cookie;
  cookieAccepted: boolean = false;
  httpRequesSended: boolean = false;
  firstFormFieldDiv: any;
  firstFormField: any;
  emailFormField: any;

  /**
   * GenericComponent constructor
   * @param route
   * @param formService
   * @param cookieService
   * @param loadingService
   * @param localization
   */
  constructor(
    private route: ActivatedRoute,
    private formService: FormService,
    private cookieService: CookiesService,
    private loadingService: LoadingService,
    private localization: LocalizationService,
    private navigation: NavigationService
  ) {
  }

  /**
   * ng on init function
   */
  ngOnInit(): void {
    this.formId = this.route.snapshot.paramMap.get('form_id');
    this.localization.lang.subscribe(() => {
      this.getForm(this.formId);
    });

    this.cookieService.cookieAccepted.subscribe((cookie) => {
      this.cookieAccepted = cookie;
      this.getCookiesConfig();
    });
  }

  /**
   * Get contact form fields
   */
  getForm(formId: string): void {
    if (!this.httpRequesSended) {
      this.formService.getForm(formId)
        .subscribe((form: Form) => {
          this.loadingService.stopLoading();
          this.form = form;
          this.form.fields.sort(function (a: any, b: any) {
            return a.weight - b.weight;
          });
          setTimeout(() => {
            this.localization.insertCaptions();
            this.fillTitle();
          }, 1000);
          this.httpRequesSended = true;
        });
    }
  }

  /**
   * get cookie config
   */
  getCookiesConfig(): void {
    this.cookieService.getCookiesConfig()
      .subscribe((cookie: Cookie) => {
        this.cookieConfig = cookie;
        this.recapcha = cookie.cookie_re_captcha;
        this.recapchaSiteKey = cookie.cookie_re_captcha_site_key;
      });
  }

  /**
   * cancel submit
   */
  submit(form) {
    this.loadingService.startLoading();
    let isValid: boolean = true;
    let data = form.value;

    Object.keys(data).forEach(key => {
      document.getElementById(key).classList.remove('error');
      let value = data[key];
      if (key == "field_title") {
        data['field_title'] = document.getElementById(key).getAttribute('ng-value');
      }

      if (key == 'field_select_all_tags') {
        var checkbox = document.getElementById(key) as HTMLInputElement | null;
        checkbox.checked == true ? value = 'true' : value = 'false';
        // Force value to be readable and selected for content creation and mail sending
        data[key] = value;
      }

      if (key.includes('option_tag_')) {
        var checkbox = document.getElementById(key) as HTMLInputElement | null;
        var select_all_tags_checkbox = document.getElementById('field_select_all_tags') as HTMLInputElement | null;
        select_all_tags_checkbox.checked == true || checkbox.checked == true ? value = 'true' : value = 'false';
        data[key] = value;
      }

      if (key == 'field_privacy_disclamer' && !value) {
        document.getElementById(key).parentElement.classList.add('error');
        isValid = false;
      } else if (document.getElementById(key).hasAttribute('required') && (!value || value == 'null')) {
        document.getElementById(key).classList.add('error');
        isValid = false;
      }
    });

    if (!this.cookieAccepted || (this.recapcha && !this.recapchaValid)) {
      isValid = false;
    }

    if (isValid) {
      data['recipients'] = this.form.config.recipients.join(',');
      this.formService.sendMail(this.formId, data)
        .subscribe((resp: any) => {
          this.loadingService.stopLoading();
          this.sended = form.submitted;
          if (this.sended) {
            window.open(this.form.config.download_file, "_blank");
            document.getElementById('generic-section').scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        });
    } else {
      this.loadingService.stopLoading();
    }

  }

  /**
   * resolved re-captcha
   *
   * @param captchaResponse
   */
  resolved(captchaResponse: string) {
    this.recapchaValid = true;
  }

  /**
   * cancel submit
   */
  cancel(): void {
  }

  /**
   * hide cookie modal
   */
  accept(): boolean {
    this.cookieService.setCookie(this.cookieService.cookieName, "yes");
    this.cookieService.acceptCookie(true);
    return false;
  }

  /**
  * Inject page title in a hidden field
  */
  fillTitle() {
    this.firstFormFieldDiv = document.querySelector('#field_title').parentElement.parentElement;
    this.firstFormField = document.querySelector('#field_title');

    if (this.firstFormFieldDiv) {
      this.firstFormFieldDiv.classList.add('hidden');
      this.firstFormField.value = this.form?.config?.title;
      this.firstFormField.setAttribute('ng-value', this.form?.config?.title);
    }
  }

  back(): void {
    this.navigation.back()
  }

  getPageUrl(): string {
    return window.location.href;
  }
}
