import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalizationService } from './localization.service';
import { Observable } from 'rxjs';
import { References, ReferencesConfig } from '../models';
import { map, catchError } from 'rxjs/operators';
import { LoadingService } from "./loading.service";


@Injectable({
  providedIn: 'root'
})
export class ReferenceService extends RestService {

  constructor( private loadingService: LoadingService, http: HttpClient, localization: LocalizationService ) {
    super(http, localization);
  }

  /**
   * get references config
   */
  getReferencesConfig(): Observable<any> {
    return this.http.get<ReferencesConfig>(ReferenceService.apiHost + 'references/config').pipe(
      map((res: ReferencesConfig) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return ReferenceService.handleError(err);
      })
    );
  }

  /**
   * get references list
   */
  getReferencesList(start='0', length='10'): Observable<any> {
    const params = new HttpParams()
      .set('start', start)
      .set('length', length);

    return this.http.get<References>(ReferenceService.apiHost + 'references/list', {params}).pipe(
      map((res) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return ReferenceService.handleError(err);
      })
    );
  }

  /**
   * get reference by id
   * @param node_id
   */
  getReferenceDetails(node_id: number): Observable<any> {
    return this.http.get<any>(ReferenceService.apiHost + 'reference/' + node_id).pipe(
      map((res) => {
        this.localization.setAliases(res.aliases);
        return res.reference
      }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return ReferenceService.handleError(err);
      })
    );
  }

}
