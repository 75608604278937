import { Component, OnInit } from '@angular/core';
import { Footer, Link, Form } from '../../models';
import { LocalizationService, FooterService, CommonService, FormService, LoadingService } from '../../services';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  protected static currentLang;
  footer: Footer;
  links: Array<Link> = [];
  toDay: Date = new Date();
  formId: string;
  form: Form;
  sended: boolean = false;
  httpRequesSended: boolean = false;

  /**
   * FooterComponent constructor
   * @param router
   * @param route
   * @param formService
   * @param loadingService
   * @param commonService
   * @param footerService
   * @param localization
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formService: FormService,
    private loadingService: LoadingService,
    private commonService: CommonService,
    private footerService: FooterService,
    private localization: LocalizationService
    ) {}

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    // watch selected lang, then translate home slider
    this.localization.lang.subscribe((lang) => {
      FooterComponent.currentLang = lang;
      if (lang.length > 0) {
        this.getFooter()
      }
      this.getNewsletterForm(); // JMJ-NEWSLETTER
    });
  }

  /**
   * Get footer elements
   */
  getFooter(): void {
    this.footerService.getFooterElements()
      .subscribe((footer: Footer) => {
        this.footer = footer;
      });

    this.getFooterMenu();
  }

  /**
   * Get footer elements
   */
  getFooterMenu(): void {
    this.footerService.getFooterMenuLinks()
      .subscribe((links: Array<Link>) => {
        this.links = links;
      });
  }

  goTo(route: Link) {
    return this.commonService.goToLink(route);
  }

  /**
   * Get newsletter form fields
   */
  getNewsletterForm(): void {
    this.formService.getNewsLetterForm('newsletter-form')
      .subscribe((form: Form) => {
        this.loadingService.stopLoading();
        this.form = form;
        this.form.fields.sort(function (a: any, b: any) {
          return a.weight - b.weight;
        });
      });
  }

  /**
   * Submit form
   */
  submit(form) {
    this.loadingService.startLoading();
    let isValid: boolean = true;
    let data = form.value;
    Object.keys(data).forEach(key => {
      document.getElementById(key).classList.remove('error');
      let value = data[key];
      if (key == 'field_newsletter_disclaimer' && !value) {
        document.getElementById(key).parentElement.classList.add('error');
        isValid = false;
      } else if (document.getElementById(key).hasAttribute('required') && (!value || value == 'null')) {
        document.getElementById(key).classList.add('error');
        isValid = false;
      }
    });

    if (isValid) {
      this.loadingService.stopLoading();
      this.loadingService.startLoading();

      this.router.navigate([FooterComponent.currentLang + '/cat-subscribe'], { queryParams: { email: data['field_newsletter_email'] } })
        .then(() => {
          let el = document.getElementById('top-hide-header');
          el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        });
      this.sended = true;
    } else {
      this.loadingService.stopLoading();
    }
  }
}
