import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { CommonService } from "../../../services";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit, AfterViewInit {

  @Input() content: any;

  constructor (
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.expandAllPanels();
  }

  /**
   * scroll to response
   *
   * @param id
   */
  scrollToResponse(id) {
    setTimeout(() => {
      const collapse = document.getElementById("collapse-" + id);
      if (collapse.classList.contains('show')) {
        let position = document.getElementById("position-" + id);
        position.classList.remove("h-auto");
        position?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
      }
    }, 500);
  }

  /**
   * got to url
   *
   * @param route
   */
  goTo(route: any) {
    return this.commonService.goToUrl(route);
  }

  /**
   * Expand all panels initially
   */
  expandAllPanels(): void {
    this.content.questions.forEach((_, index) => {
      const collapse = document.getElementById(`collapse-${index}`);
      collapse.classList.add('show');
    });
  }
}
