import { Component, OnInit } from '@angular/core';
import {Reference, ReferencesConfig, References, Generic} from "../../../models";
import { HomepageService } from "../../../services/homepage.service";
import { LocalizationService } from "../../../services/localization.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService, LoadingService, ReferenceService } from "../../../services";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.css']
})
export class ReferenceComponent implements OnInit {

  currentLang: string;
  referenceId: string;
  referenceDetails: Reference;
  referencesList: References;
  referencesConfig: ReferencesConfig;
  researched_path: number = 0;
  prevPath: number = 0;
  prevTitle: string;
  nextPath: number = 0;
  nextTitle: string;

  // slider config
  slideConfig: object = {
    "adaptiveHeight": true,
    "arrows": true,
    "autoplay": true,
    "autoplaySpeed": 7000,
    "dots": true,
    "slide": 'div',
    "speed": 500
  };

  /**
   * LeaderBoardComponent constructor
   * @param homepageService
   * @param localization
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private loadingService: LoadingService,
    private localization: LocalizationService,
    private referenceService: ReferenceService
  ) {
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.referenceId = params['node_id'];
      this.localization.lang.subscribe(lang => {
        this.currentLang = lang;
        /**
         * Get getReferencesConfig first, then getReferenceList, and finaly
         * getReferenceDetails, but sequentially.
         */
        this.getReferencesConfig();
      });
    });
  }

  /**
   * Get References Config
   */
  getReferencesConfig(): void {
    this.referenceService.getReferencesConfig()
      .subscribe((referencesConfig: ReferencesConfig) => {
        this.referencesConfig = referencesConfig;
        /**
         * Now getReferenceList, and finaly
         * getReferenceDetails, but sequentially.
         */
        this.getReferencesList();
      });
  }

  /**
   * Get references list
   */
  getReferencesList(): void {
    this.referenceService.getReferencesList()
      .subscribe((references: References) => {
        this.referencesList = references;
        /**
         * Time to finally
         * getReferenceDetails.
         */
        this.getReferenceDetails(this.referenceId);
      });
  }

  /**
   * Get reference details
   */
  getReferenceDetails(referenceId): void {
    this.referenceService.getReferenceDetails(referenceId)
      .pipe(
        tap((reference: Reference) => {
          if (!this.isValid(reference)) {
            this.router.navigate(['/']);
          }
        })
      )
      .subscribe((reference: Reference) => {
        this.referenceDetails = reference;
        this.loadingService.stopLoading();
        this.referenceService.appendMatomo(this.referenceDetails.script_matomo);
        setTimeout(() => this.localization.insertCaptions(), 1000);
        this.loadReferencePath();
      });
  }

  /**
   * Get reference path
   */
  loadReferencePath() {
    var reference_path = this.referenceDetails.path;
    this.researched_path = this.referencesList.paths.indexOf(reference_path);
    this.prevPath = this.researched_path - 1;
    this.prevTitle = this.referencesList.titles[this.prevPath];
    this.nextPath = this.researched_path + 1;
    this.nextTitle = this.referencesList.titles[this.nextPath];
  }

  /**
   * Scroll to desired element
   * @param id, string
   */
  scroll() {
    let el = document.getElementById("scoll-position");
    el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  /**
   * Go to news details
   * newsId:number
   */
  goToList(): void {
    this.router.navigate(['/' + this.currentLang + '/references']).then(() => {
      let el = document.getElementById('header');
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }

  /**
   * Go to related products
   * newsId:number
   */
  goToRelated() {
    return this.commonService.goToUrl(this.referenceDetails.related_products);
  }

  /**
   * Go to previous or next reference
   * reference: Reference
   */
  goToReference(path): void {
    var reference_path = this.referencesList.paths[path];
    this.loadingService.startLoading();
    this.router.navigate([this.currentLang + '/reference/', reference_path]).then(() => {
      let el = document.getElementById('top-hide-header');
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }

  isValid(reference: Reference): boolean {
    return reference !== null && reference !== undefined;
  }
}
