import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { GenericService, LocalizationService, LoadingService } from "../../services";
import { Generic } from "../../models";
import { NavigationService } from '../../services/navigation.service';
import {tap} from "rxjs/operators";
import { Router } from '@angular/router';

@Component({
  selector: 'app-generic',
  templateUrl: './generic.component.html',
  styleUrls: ['./generic.component.css']
})
export class GenericComponent implements OnInit {

  nodeId: string;
  genericDetails: Generic;
  currentHttpReques: any = 0;

  /**
   * GenericComponent constructor
   * @param route
   * @param genericService
   * @param loadingService
   * @param localization
   */
  constructor(
    private route: ActivatedRoute,
    private genericService: GenericService,
    private loadingService: LoadingService,
    private localization: LocalizationService,
    private navigation: NavigationService,
    private router: Router
  ) {
  }

  /** */
  ngOnInit(): void {
    this.nodeId = this.route.snapshot.paramMap.get('node_id');
    this.localization.lang.subscribe(() => this.getGenericPageDetails(this.nodeId));
  }

  /**
   * Get Newsroom list
   */
  getGenericPageDetails(node_id): void {
    if (this.currentHttpReques == 0) {
      this.genericService.getGenericPage(node_id)
        .pipe(
          tap((generic: Generic) => {
            if (!this.isValid(generic)) {
              this.router.navigate(['/']);
            }
          })
        )
        .subscribe((generic: Generic) => {
          this.genericDetails = generic;
          this.loadingService.stopLoading();
          this.currentHttpReques++;
          this.genericService.appendMatomo(this.genericDetails.script_matomo);
          setTimeout(() => this.localization.insertCaptions(), 1000);
        });
    }
  }

  back(): void {
    this.navigation.back()
  }

  isValid(generic: Generic): boolean {
    return generic !== null && generic !== undefined;
  }
}
