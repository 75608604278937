<section class="generic-header" [style.background-image]="'url(' + form?.config?.image + ')'">
  <div class="text-container">
    <h1 class="title"> {{ form?.config?.title }} </h1>
    <div class="content">
      {{ form?.config?.subtitle }}
    </div>
  </div>
</section>

<section class="generic-breadcrumb" id="generic-section">
  <div class="row">
    <div class="col-sm-12">
      <a href="/"><img src="../assets/img/icon/home.png" alt="home"/></a> |
      <span> {{ form?.config?.title }} </span>
    </div>
  </div>
  <div class="sharing_container">
    <dl class="share">
      <dt class="toggle_share">
        <button class="share_toggle" title="Share icon">
          <img src="../assets/img/icon/Share_default.svg" alt="share icon"/>
        </button>
      </dt>
      <dd class="toggle_pane">
        <ul class="share_list">
          <li class="share_item">
            <a href="https://www.facebook.com/sharer/sharer.php?u={{ getPageUrl() }}&quote={{ form?.config?.title }}" title="share on Facebook" target="_blank" class="share_link">
              <svg class="icon" width="65" height="65" viewBox="0 0 65 65" xmlns="http://www.w3.org/2000/svg">
                <path d="M37 27.375H34.8366C34.1436 27.375 33.625 27.6585 33.625 28.3751V29.625H37L36.7311 33H33.625V42H30.25V33H28V29.625H30.25V27.4616C30.25 25.1869 31.447 24 34.1436 24H37V27.375Z"/>
              </svg>
            </a>
          </li>
          <li class="share_item">
            <a href="https://twitter.com/intent/tweet?text={{ form?.config?.title }}&url={{ getPageUrl() }}" title="share on Twitter" target="_blank" class="share_link">
              <svg class="icon" width="65" height="65" viewBox="0 0 65 65" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.9755 41C26.7739 41 24.7254 40.3318 23 39.1849C25.0686 39.4372 27.1325 38.8428 28.7712 37.5125C27.0648 37.4793 25.6256 36.3115 25.1304 34.7068C25.7419 34.8274 26.3428 34.7917 26.8902 34.6379C25.0152 34.2478 23.722 32.4979 23.7636 30.6262C24.2884 30.9289 24.8905 31.1111 25.5282 31.132C23.7932 29.9297 23.3016 27.5547 24.3217 25.7383C26.2443 28.1823 29.1168 29.7906 32.3563 29.9592C31.7875 27.4329 33.6376 25 36.1539 25C37.2749 25 38.2879 25.4898 38.9992 26.2761C39.8874 26.0952 40.7222 25.7593 41.4751 25.2966C41.1842 26.2404 40.5655 27.0317 39.7616 27.5313C40.5501 27.4329 41.3018 27.2163 42 26.8951C41.4775 27.7024 40.8161 28.4136 40.0549 28.9834C40.3019 34.665 36.2133 41 28.9755 41ZM28.9755 41C26.7739 41 24.7254 40.3318 23 39.1849C25.0686 39.4372 27.1325 38.8428 28.7712 37.5125C27.0648 37.4793 25.6256 36.3115 25.1304 34.7068C25.7419 34.8274 26.3428 34.7917 26.8902 34.6379C25.0152 34.2478 23.722 32.4979 23.7636 30.6262C24.2884 30.9289 24.8905 31.1111 25.5282 31.132C23.7932 29.9297 23.3016 27.5547 24.3217 25.7383C26.2443 28.1823 29.1168 29.7906 32.3563 29.9592C31.7875 27.4329 33.6376 25 36.1539 25C37.2749 25 38.2879 25.4898 38.9992 26.2761C39.8874 26.0952 40.7222 25.7593 41.4751 25.2966C41.1842 26.2404 40.5655 27.0317 39.7616 27.5313C40.5501 27.4329 41.3018 27.2163 42 26.8951C41.4775 27.7024 40.8161 28.4136 40.0549 28.9834C40.3019 34.665 36.2133 41 28.9755 41Z"/>
              </svg>
            </a>
          </li>
          <li class="share_item">
            <a href="https://www.linkedin.com/shareArticle?mini=true&url={{ getPageUrl() }}" title="share on LinkedIn" target="_blank" class="share_link_lk">
              <svg class="icon" width="65" height="65" viewBox="0 0 65 65" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.0492 42H23.3115V28.7688H27.0492V42ZM25.1803 27.2436C23.9768 27.2436 23 26.2934 23 25.1218C23 23.9502 23.9768 23 25.1803 23C26.3839 23 27.3607 23.9502 27.3607 25.1218C27.3607 26.2934 26.3851 27.2436 25.1803 27.2436ZM42 42H38.2623V35.2593C38.2623 31.2082 33.2787 31.5149 33.2787 35.2593V42H29.541V28.7688H33.2787V30.8918C35.0192 27.7813 42 27.5515 42 33.87V42Z"/>
              </svg>
            </a>
          </li>
        </ul>
      </dd>
    </dl>
  </div>
</section>

<section class="generic-content pb-5" *ngIf="form && !sended">
  <div class="row">
    <div class="col-md-12 col-lg-8 col-sm-12 content" [innerHTML]="form?.config?.content | safeHtml">
    </div>

    <div class="col-lg-4 col-md-12 col-sm-12 pl-md-5 Form_92">
      <h3 class="pt-3 text-center text-muted"> {{ form?.config?.form_title }} </h3>
      <form class="form-contact" name="contactForm" #contactForm="ngForm" (ngSubmit)="submit(contactForm)">
        <div *ngFor="let field of form?.fields" [ngSwitch]="field.type" class="col-sm-12">
          <div class="form-group" *ngSwitchCase="'textfield'">
            <input type="text" class="form-control" [name]="field.id" [id]="field.id" [required]="field.required"
                   [placeholder]="field.required ? field.title + '*' : field.title" ngModel/>
          </div>

          <div class="form-group" *ngSwitchCase="'email'">
            <input type="text" class="form-control" [name]="field.id" [id]="field.id" [required]="field.required"
              [placeholder]="field.required ? field.title + '*' : field.title" ngModel />
          </div>

          <div class="form-group" *ngSwitchCase="'textarea'">
            <textarea class="form-control" [name]="field.id" [id]="field.id" [required]="field.required"
                      [placeholder]="field.title" style="height: 310px" ngModel></textarea>
          </div>

          <div class="form-group" *ngSwitchCase="'select'">
            <select class="form-control amc-select" [name]="field.id" [id]="field.id" [required]="field.required" [ngModel]="field.options[0].id">
              <option [value]="" [selected]="true">
                {{ field.title }}
              </option>
              <option *ngFor="let option of field.options" [value]="option.id">
                {{ option.value }}
              </option>
            </select>
          </div>

          <div class="form-group" *ngSwitchCase="'checkbox'">
            <label *ngIf="field.id != 'field_select_all_tags'" class="checkbox-container">
              {{ field.title }}
              <input type="checkbox" [name]="field.id" [id]="field.id" [required]="field.required" ngModel>
              <span class="checkmark"></span>
            </label>

            <label *ngIf="field.id == 'field_select_all_tags'" class="checkbox-container">
              {{ field.title }}
              <input type="checkbox" [name]="field.id" [id]="field.id" [required]="field.required" ngModel (change)="selectAllCheckboxes($event)">
              <span class="checkmark"></span>
            </label>
          </div>

          <div class="form-group" *ngSwitchCase="'checkboxes'">
              <label class="checkbox-container" *ngFor="let option of field.options" [value]="option.id">
                {{ option.value }}
                <input type="checkbox" [name]="option.id" [id]="option.id" ngModel (change)="unselectCheckbox($event)">
                <span class="checkmark"></span>
              </label>
          </div>
        </div>

        <div class="col-sm-12 mb-3" *ngIf="cookieAccepted && recapcha">
          <re-captcha class="captcha_class" (resolved)="resolved($event)" siteKey="{{ recapchaSiteKey }}"></re-captcha>
        </div>

        <div class="contact_btn col-sm-12 mb-3" *ngIf="!cookieAccepted">
          <div class="text-white" [innerHTML]="cookieConfig?.cookie_form_text?.value"></div>
          <button type="button" class="form_btn amc-btn-light" (click)="accept()">
            {{ cookieConfig?.cookie_accept_text }} Cookies
          </button>
        </div>

        <div class="contact_btn col-sm-12">
          <button type="submit" class="amc-btn-light-contact d-flex">
            {{ form?.config?.submit_text }}
            <img src="/assets/img/icon/arrow_cta.png" class="white" alt="Arrow CTA">
          </button>
        </div>
      </form>
    </div>
  </div>
</section>

<section class="generic-content" *ngIf="sended">
  <div class="row">
    <div class="col-sm-12 content" [innerHTML]="form?.config?.confirmation_message">
    </div>
  </div>
</section>
