<div class="generic-content col-md-12 p-0">
  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
    <div class="panel panel-default position-relative mb-1" *ngFor="let question of content?.questions; let i = index">
      <div class="position" id="position-{{i}}"></div>
      <div class="panel-heading" role="tab" id="bloc-{{i}}">
        <h4 class="panel-title mb-0">
          <a role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="true" id="link-{{i}}"
             href="#collapse-{{i}}" aria-controls="collapse-{{i}}" (click)="scrollToResponse(i)">
            <img [src]="question.icon" class="icon" alt="icon" />
            {{ question.question }}
          </a>
        </h4>
      </div>
      <div id="collapse-{{i}}" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="bloc-{{i}}">
        <div class="panel-body content row">
          <div class="col-md-6 col-sm-12">
            <img *ngIf="question.image != null" class="faq-img" [src]="question.image"  alt="icon"/>
          </div>
          <div class="col-sm-12 faq-text" [class.col-md-6]="question.image != null">
            <div [innerHTML]="question.response | safeHtml"></div>
            <div class="readmore" *ngIf="question?.link?.url != '' && question?.link?.title != null">
              <a (click)="goTo(question?.link)" href="#" class="amc-btn mt-3 pull-right text-decoration-none">
                {{ question?.link?.title }}
                <img src="/assets/img/icon/arrow_red.png" class="orange" alt="Arrow red">
                <img src="/assets/img/icon/arrow_cta.png " class="grey" alt="Arrow red">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
